import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { IAttraction } from '@/types/attraction.model';
import styled from 'styled-components';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

interface Props {
  className?: string;
  attractions: Partial<IAttraction>[];
}

const MapWithAttractions: React.FC<Props> = ({ className, attractions }) => {
  const mapContainer = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current!,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: attractions[0].location?.coordinates,
      zoom: 9,
    });

    // Add a new source from our GeoJSON data and
    // set the 'cluster' option to true. GL-JS will
    // add the point_count property to your source data.

    const features = attractions.map((attraction, index) => {
      const { location: geometry } = attraction;
      return {
        type: 'Feature',
        geometry,
        properties: {
          title: `#${index + 1}`,
          index: index + 1,
        },
      };
    });

    map.on('load', () => {
      map.loadImage('/img/marker.png', (error: any, image: any) => {
        if (error) throw error;

        // Add the image to the map style.
        map.addImage('my-marker', image);
        map.addSource('attractions', {
          type: 'geojson',
          //data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
          data: {
            type: 'FeatureCollection',
            features,
          },
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'attractions',
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          },
        });

        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'attractions',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });

        map.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'attractions',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'text-color': '#ffffff',
          },
          layout: {
            'icon-image': 'my-marker',
            'icon-size': 0.33,
            'text-field': ['get', 'index'],
            'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });

        // inspect a cluster on click
        map.on('click', 'clusters', (e: any) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          const clusterId = features[0].properties.cluster_id;
          map.getSource('attractions').getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        map.on('click', 'unclustered-point', (e: any) => {
          const coordinates = e.features[0].geometry.coordinates.slice();

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup().setLngLat(coordinates).addTo(map);
        });

        map.on('mouseenter', 'clusters', function () {
          map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'clusters', function () {
          map.getCanvas().style.cursor = '';
        });

        const bounds = new mapboxgl.LngLatBounds();

        features.forEach((feature) => {
          bounds.extend(feature.geometry?.coordinates);
          // new mapboxgl.Marker({ color: '#546396' }).setLngLat(feature.geometry?.coordinates).addTo(map);
        });

        map.fitBounds(bounds, { maxZoom: 16 });
      });
    });

    return () => map.remove();
  }, [attractions]);

  return <div className={className} ref={mapContainer} />;
};

export default styled(MapWithAttractions)`
  z-index: 1;
  position: relative;
  width: 100%;
  height: 345px;
  margin-bottom: 20px;
`;
