import ContributionList from '@/components/ContributionList';
import PerceptionList from '@/components/PerceptionList';
import { useAppSelector } from '@/redux/hooks';
import { IWalk } from '@/types/walk.model';
import { gql, useQuery } from '@apollo/client';
import { ArrowLeft16, ArrowRight16 } from '@carbon/icons-react';
import { Button, Result, Skeleton } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const Attraction: React.FC<Props> = ({ className }) => {
  const { walkId, attractionIndex } = useParams() as any;
  const myUserId = useAppSelector((state) => state.auth.userId);
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_ATTRACTION, {
    variables: {
      walkId,
      index: parseInt(attractionIndex),
    },
    fetchPolicy: 'network-only',
  });

  if (!loading && data) {
    const walk = data.walkWithOneAttraction as Partial<IWalk>;
    const { name, next, previous, author, attraction } = walk;
    return (
      <div className={className}>
        {data && (
          <div className="container">
            <header>
              <div>
                <div className="left">
                  <h1>
                    <Link to={`/walks/${walkId}`}> {name} </Link>/ <span>#{attractionIndex}</span>
                  </h1>{' '}
                </div>
                <div className="right">
                  <Button
                    type="text"
                    disabled={!previous}
                    onClick={() => {
                      history.push(`/walks/${walkId}/${parseInt(attractionIndex) - 1}`);
                    }}
                  >
                    <ArrowLeft16 />
                  </Button>
                  <Button
                    type="text"
                    disabled={!next}
                    onClick={() => {
                      history.push(`/walks/${walkId}/${parseInt(attractionIndex) + 1}`);
                    }}
                  >
                    <ArrowRight16 />
                  </Button>
                </div>
              </div>
              <span className="author">@{author?.name}</span>
            </header>
            {!!attraction?.image && <img src={attraction?.image} />}
            <h2>{attraction?.title}</h2>
            <p>{attraction?.description}</p>
            <PerceptionList attraction={attraction!} />
            <ContributionList attraction={attraction!} editable={walk.author?._id === myUserId} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={className}>
        <div className="container">
          {loading && <Skeleton active={true} />}
          {!!error && <Result status="warning" title="Le point d'intérêt n'a pas pu être récupéré." />}
        </div>
      </div>
    );
  }
};

export default styled(Attraction)`
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  padding: 55px 20px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container {
    flex: 1;
    width: 100%;
    header {
      margin-bottom: 20px;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          &.right {
            button {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    img {
      width: 100%;
    }
    h2 {
      margin-top: 20px;
      margin-bottom: 24px;
      line-height: 26px;
    }
    p {
      white-space: pre-line;
    }
  }

  h1 {
    font-weight: normal;
    margin: 0;
    color: #9b9b9b;
    a {
      color: inherit;
    }
    span {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .author {
    color: #4a4a4a;
  }
`;

const GET_ATTRACTION = gql`
  query Attraction($walkId: ID!, $index: Int!) {
    walkWithOneAttraction(input: { walkId: $walkId, index: $index }) {
      _id
      name
      createdAt
      next
      previous
      attraction {
        _id
        title
        description
        image
        location {
          type
          coordinates
        }
        lastComments {
          _id
          nickname
          text
          image
          hidden
          createdAt
        }
        num_perception
        future_perception {
          nature
          mobility
          life
          identity
          health
          security
        }
        present_perception {
          nature
          mobility
          life
          identity
          health
          security
        }
      }
      author {
        _id
        name
      }
    }
  }
`;
