import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { IAttraction } from '@/types/attraction.model';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';

interface Props {
  className?: string;
  position: number;
  attraction: Partial<IAttraction>;
  onWantDelete?: (id: string) => void;
  onWantUpdate?: (attraction: Partial<IAttraction>) => void;
}

const ContextMenu: React.FC<{
  attraction: Partial<IAttraction>;
  onWantDelete?: (id: string) => void;
  onWantUpdate?: (attraction: Partial<IAttraction>) => void;
  close: () => void;
}> = ({ attraction, onWantDelete, onWantUpdate, close }) => {
  return (
    <Menu onClick={close}>
      {!!onWantUpdate && (
        <Menu.Item key="update" onClick={() => onWantUpdate(attraction)}>
          Modifier
        </Menu.Item>
      )}
      {!!onWantDelete && (
        <Menu.Item danger key="delete" onClick={() => onWantDelete(attraction._id!)}>
          Supprimer
        </Menu.Item>
      )}
    </Menu>
  );
};

const AttractionCard: React.FC<Props> = ({ className, position, attraction, onWantDelete, onWantUpdate }) => {
  const history = useHistory();
  const { walkId } = useParams() as any;
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  if (!!onWantDelete || !!onWantUpdate) {
    return (
      <Dropdown
        overlay={() => (
          <ContextMenu
            attraction={attraction}
            onWantDelete={onWantDelete}
            onWantUpdate={onWantUpdate}
            close={() => {
              setShowContextMenu(false);
            }}
          />
        )}
        trigger={['contextMenu']}
        visible={showContextMenu}
        onVisibleChange={(e) => setShowContextMenu(e)}
      >
        <div
          key={attraction._id}
          className={`${className} card`}
          style={{ backgroundImage: `url("${attraction.image}")` }}
          onClick={() => {
            history.push(`/walks/${walkId}/${position}`);
          }}
        >
          <div className="black-gradient" />
          <div className="container">
            <h3>
              #{position} {attraction.title}
            </h3>
          </div>
        </div>
      </Dropdown>
    );
  } else {
    return (
      <div
        key={attraction._id}
        className={`${className} card`}
        style={{ backgroundImage: `url("${attraction.image}")` }}
        onClick={() => {
          history.push(`/walks/${walkId}/${position}`);
        }}
      >
        <div className="black-gradient" />
        <div className="container">
          <h3>
            #{position} {attraction.title}
          </h3>
        </div>
      </div>
    );
  }
};

export default styled(AttractionCard)`
  background-size: cover;
  background-position: center;
  .container {
    z-index: 1;
    text-align: left;

    h3 {
      color: white;
      font-style: bold;
    }

    h4 {
      color: white;
      font-style: normal;
    }
  }
`;
