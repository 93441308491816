import { gql, useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import MapWithWalks from './MapWithWalks';
import { IWalk } from '@/types/walk.model';
import { Result, Spin } from 'antd';

interface Props {
  className?: string;
}

const ExplorePage: React.FC<Props> = ({ className }) => {
  const { loading, error, data } = useQuery(GET_WALKS, {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Spin spinning={loading} size="large" />;
  }

  if (data) {
    const walks = data.walks as IWalk[];
    return (
      <div className={className}>
        {data && (
          <div className="container">
            <MapWithWalks walks={walks} />
          </div>
        )}
      </div>
    );
  }

  return <Result status="error" title="Oops" subTitle="Failed to retrieve the data" />;
};

export default ExplorePage;

const GET_WALKS = gql`
  query Walks {
    walks {
      _id
      name
      createdAt
      center
      author {
        _id
        name
      }
    }
  }
`;
