import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { Add32 } from '@carbon/icons-react';
import { IAttraction } from '@/types/attraction.model';
import { apolloClient } from '@/apollo';
import ModalCreatePerception from './ModalCreatePerception';
import { Empty } from 'antd';
import Perception from './Perception';
import ModalPerception from './ModalPerception';

interface Props {
  className?: string;
  attraction: Partial<IAttraction>;
}

const PerceptionList: React.FC<Props> = ({ className, attraction }) => {
  const [showModalCreatePerception, setShowModalCreatePerception] = useState<boolean>(false);
  const [showPerception, setShowPerception] = useState<{
    num_perception: number;
    future_perception: IAttraction['present_perception'];
    present_perception: IAttraction['future_perception'];
  } | null>(null);

  const [perception, setPerception] = useState<{
    num_perception: number;
    future_perception: IAttraction['present_perception'];
    present_perception: IAttraction['future_perception'];
  } | null>();

  useEffect(() => {
    if (attraction) {
      const { num_perception, future_perception, present_perception } = attraction as IAttraction;

      setPerception({
        num_perception,
        future_perception,
        present_perception,
      });

      const subscription = apolloClient
        .subscribe({
          query: PERCEPTION_SUBSCRIPTION,
          variables: {
            attractionId: attraction._id,
          },
        })
        .subscribe({
          next: ({ data, errors }) => {
            const { perceptionAdded } = data;
            if (perceptionAdded) {
              setPerception({
                ...perceptionAdded,
              });
            }
          },
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [attraction]);

  return (
    <div className={className}>
      <h2>Perceptions</h2>
      <div className="container">
        <div className="card new" onClick={() => setShowModalCreatePerception(true)}>
          <Add32 width={64} height={64} />
          Ajouter une perception
        </div>
        <div className="card">
          {!perception?.num_perception && <Empty />}
          {!!perception?.num_perception && (
            <div onClick={() => setShowPerception(perception)}>
              <Perception perception={perception!} />
            </div>
          )}
        </div>
      </div>
      {/* <ModalComment comment={currentComment} onClose={() => setCurrentComment(null)} /> */}
      <ModalCreatePerception
        attractionId={attraction._id!}
        visible={showModalCreatePerception}
        onClose={() => setShowModalCreatePerception(false)}
      />
      <ModalPerception perception={showPerception} onClose={() => setShowPerception(null)} />
    </div>
  );
};

export default styled(PerceptionList)`
  margin: 35px 0;

  .container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .card {
    display: flex;
    align-items: center;
    width: calc(50% - 5px);
    height: 290px;
    margin: 5px 0;
    padding: 10px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    &:nth-of-type(odd) {
      margin-right: 5px;
    }
    &:nth-of-type(even) {
      margin-left: 5px;
    }

    @media only screen and (max-width: 400px) {
      width: 100%;
      margin: 5px 0 !important;
    }

    h3 {
      margin: 0;
    }
  }
`;

const PERCEPTION_SUBSCRIPTION = gql`
  subscription OnSubscriptionAdded($attractionId: ID!) {
    perceptionAdded(attractionId: $attractionId) {
      num_perception
      present_perception {
        nature
        mobility
        life
        identity
        health
        security
      }
      future_perception {
        nature
        mobility
        life
        identity
        health
        security
      }
    }
  }
`;
