import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IComment } from '@/types/comment.model';
import { useLocation, useParams } from 'react-router';
import { apolloClient } from '@/apollo';
import { gql } from '@apollo/client';
import { message, Skeleton } from 'antd';
import moment from 'moment';

interface Props {
  className?: string;
}

const Comment: React.FC<Props> = ({ className }) => {
  const location = useLocation();
  const { commentId } = useParams() as any;

  const [comment, setComment] = useState<Partial<IComment> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const comment = (location.state as any)?.comment as Partial<IComment>;
    if (comment) {
      setComment(comment);
    } else {
      setIsLoading(true);
      apolloClient
        .query({
          query: GET_COMMENT,
          variables: {
            commentId,
          },
        })
        .then(({ data }) => {
          setComment(data.comment);
          setIsLoading(false);
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  }, []);

  if (!comment || isLoading) {
    return (
      <div className={className}>
        <div className="container">
          <Skeleton active={true} />
        </div>
      </div>
    );
  }

  if (comment?.image) {
    return (
      <div className={className}>
        <div className="container with-image">
          <div className="image" style={{ backgroundImage: `url("${comment.image}")` }}>
            <div className="bottom" />
            <div className="date">{moment(comment.createdAt!).format('L')}</div>
          </div>
          <p>{comment.text}</p>
          <span className="author">{comment.nickname}</span>
          <div className="theme">{/* <span className="dot" /> {comment.theme} */}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={className}>
        <div className="container">
          <div className="top">
            <div className="theme">{/* <span className="dot" /> {comment.theme} */}</div>
            <div className="date">{moment(comment.createdAt!).format('L')}</div>
          </div>
          <p>{comment.text}</p>
          <span className="author">{comment.nickname}</span>
        </div>
      </div>
    );
  }
};

export default styled(Comment)`
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 55px 20px;

  .container {
    position: relative;
    width: 100%;
    max-width: 590px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      .date {
        color: #9b9b9b;
      }
    }

    &.with-image {
      text-align: left;
      justify-content: flex-start;
      .author {
        text-align: right;
      }
      .theme {
        position: absolute;
      }

      .image {
        background-position: center;
        background-size: cover;
        border-radius: 4px;
        width: 100%;
        height: 345px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        .bottom {
          background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0.8;
        }

        .date {
          position: absolute;
          bottom: 5px;
          right: 5px;
          color: white;
        }
      }
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      overflow: hidden;
      line-height: 18px;
      padding: 0;
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 5px;
      text-align: left;
    }

    .author {
      width: 100%;
      font-style: italic;
      color: #9b9b9b;
      display: inline-block;
      text-align: right;
    }

    .theme {
      border-radius: 20px;
      background: black;
      color: white;
      padding: 2px 10px 2px 8px;
      font-size: 12px;
      top: 20px;
      left: 20px;
      display: flex;
      align-items: center;

      .dot {
        display: inline-block;
        background-color: green;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        margin-right: 5px;
      }
    }
  }
`;

const GET_COMMENT = gql`
  query Comment($commentId: ID!) {
    comment(commentId: $commentId) {
      _id
      nickname
      text
      image
      hidden
      createdAt
    }
  }
`;
