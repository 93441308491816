import React from 'react';
import styled from 'styled-components';
import { ReactComponent as WalksIllustration } from '../../../svg/walks-illustration.svg';
import { useAppSelector } from '@/redux/hooks';
import WalkCardList from '@/components/WalkCardList';

interface Props {
  className?: string;
}

const Walks: React.FC<Props> = ({ className }) => {
  const userId = useAppSelector((state) => state.auth.userId);

  return (
    <div className={className}>
      <WalksIllustration id="walks-illustration" />
      <WalkCardList userId={userId!} />
    </div>
  );
};

export default styled(Walks)`
  text-align: center;
  padding: 55px 20px;

  #walks-illustration {
    width: 100%;
    max-width: 210px;
    #map {
      animation: fadeInUp 0.5s;
    }
    #markers g {
      opacity: 0;
      animation: dropIn 2s ease forwards;
      &:nth-of-type(1) {
        animation-delay: calc(0ms);
      }
      &:nth-of-type(2) {
        animation-delay: calc(200ms);
      }
      &:nth-of-type(3) {
        animation-delay: calc(400ms);
      }
    }
  }

  @keyframes dropIn {
    20% {
      opacity: 0;
      transform: translateY(-20%);
    }
    30%,
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;
