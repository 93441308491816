import { apolloClient } from '@/apollo';
import AttractionCardList from '@/components/AttractionCardList';
import { IAttraction } from '@/types/attraction.model';
import { IWalk } from '@/types/walk.model';
import { gql, useQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import MapWithAttractions from './MapWithAttractions';

interface Props {
  className?: string;
}

const Walk: React.FC<Props> = ({ className }) => {
  const { walkId } = useParams() as any;

  const [loading, setLoading] = useState<boolean>(false);
  const [walk, setWalk] = useState<Partial<IWalk> | null>(null);

  useEffect(() => {
    setLoading(true);
    apolloClient
      .query({
        query: GET_WALK,
        variables: {
          walkId,
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        setLoading(false);
        setWalk(response.data.walk);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const onAddAttraction = (attraction: Partial<IAttraction>) => {
    setWalk({ ...walk, attractions: [...(walk?.attractions || []), attraction] });
  };

  const onUpdateAttraction = (attraction: Partial<IAttraction>) => {
    const attractions = [...(walk?.attractions || [])];
    const attractionIndex = attractions.findIndex((x) => x._id === attraction._id);
    if (~attractionIndex) {
      attractions[attractionIndex] = attraction;
    }
    setWalk({ ...walk, attractions: attractions });
  };

  const onDeleteAttraction = (id: string) => {
    const attractions = [...(walk?.attractions || [])];
    const attractionIndex = attractions.findIndex((x) => x._id === id);
    if (~attractionIndex) {
      attractions.splice(attractionIndex, 1);
    }
    setWalk({ ...walk, attractions: attractions });
  };

  return (
    <div className={className}>
      <div className="container">
        {loading && <Skeleton active={true} />}
        {walk && (
          <React.Fragment>
            <header>
              <h1>{walk.name}</h1> <span className="author">@{walk.author?.name}</span>
            </header>
            {!!walk.attractions?.length && <MapWithAttractions attractions={walk.attractions} />}
            <AttractionCardList
              walk={walk}
              onAddAttraction={onAddAttraction}
              onUpdateAttraction={onUpdateAttraction}
              onDeleteAttraction={onDeleteAttraction}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default styled(Walk)`
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  padding: 55px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container {
    flex: 1;
    width: 100%;
    header {
      margin-bottom: 20px;
    }
  }

  h1 {
    margin: 0;
  }
  .author {
    color: #4a4a4a;
  }
`;

const GET_WALK = gql`
  query Walk($walkId: ID!) {
    walk(walkId: $walkId) {
      _id
      name
      createdAt
      attractions {
        _id
        title
        image
        description
        location {
          type
          coordinates
        }
      }
      author {
        _id
        name
      }
    }
  }
`;
