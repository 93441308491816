import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { Add32 } from '@carbon/icons-react';
import CommentCard from './CommentCard';
import { useHistory, useParams } from 'react-router';
import { IAttraction } from '@/types/attraction.model';
import InfiniteScroll from './InfiniteScroll';
import { IComment } from '@/types/comment.model';
import { apolloClient } from '@/apollo';
import ModalCreateComment from './ModalCreateComment';
import ModalComment from './ModalComment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
const { confirm } = Modal;

interface Props {
  className?: string;
  attraction: Partial<IAttraction>;
  editable?: boolean;
}

const ContributionList: React.FC<Props> = ({ className, attraction, editable }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalCreateComment, setShowModalCreateComment] = useState<boolean>(false);
  const [currentComment, setCurrentComment] = useState<Partial<IComment> | null>(null);
  const [comments, setComments] = useState<Partial<IComment>[]>([...(attraction.lastComments || [])].reverse());
  const commentsRef = useRef(comments);
  commentsRef.current = comments;
  const [hasMoreComments, setHasMoreComments] = useState<boolean>((attraction.lastComments || []).length === 5);

  useEffect(() => {
    if (attraction) {
      const subscription = apolloClient
        .subscribe({
          query: COMMENTS_SUBSCRIPTION,
          variables: {
            attractionId: attraction._id,
          },
        })
        .subscribe({
          next: ({ data }) => {
            const { commentAdded } = data;
            if (commentAdded) {
              setComments([commentAdded, ...commentsRef.current]);
            }
          },
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [attraction]);

  const onAddComment = (comment: Partial<IComment>) => {
    const commentsUpdated = [comment, ...comments];
    setComments(commentsUpdated);
  };

  const onWantHide = (id: string) => {
    confirm({
      title: 'Are you sure to hide this comment?',
      icon: <ExclamationCircleOutlined />,
      content: 'This operation can not be undone and you will hide the comment.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return apolloClient
          .mutate({
            mutation: HIDE_COMMENT,
            variables: {
              id,
              hidden: true,
            },
          })
          .then((response) => {
            message.info(`Le commentaire a bien été caché !`);
            onHideComment(id);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  };

  const onHideComment = (id: string) => {
    const commentsUpdated = [...comments];
    const commentIndex = commentsUpdated.findIndex((x) => x._id === id);
    if (~commentIndex) {
      commentsUpdated[commentIndex] = {
        ...commentsUpdated[commentIndex],
        hidden: true,
      };
    }
    setComments(commentsUpdated);
  };

  const onReach = () => {
    setIsLoading(true);

    apolloClient
      .query({
        query: GET_COMMENTS,
        variables: {
          attractionId: attraction._id,
          skip: comments.length,
        },
      })
      .then(({ data }) => {
        const commentsLenth = data.comments.length;
        if (commentsLenth) {
          setComments([...comments, ...data.comments]);
        }
        if (commentsLenth < 5) {
          setHasMoreComments(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  return (
    <div className={className}>
      <h2>Contributions</h2>
      <div className="container">
        <div className="card new" onClick={() => setShowModalCreateComment(true)}>
          <Add32 width={64} height={64} />
          Ajouter une contribution
        </div>
        {comments.map((comment) => (
          <CommentCard
            key={comment._id}
            comment={comment}
            onSeeComment={() => setCurrentComment(comment)}
            onWantHide={editable ? onWantHide : undefined}
          />
        ))}
        {hasMoreComments && <InfiniteScroll isLoading={isLoading} onReach={onReach} />}
      </div>
      <ModalComment comment={currentComment} onClose={() => setCurrentComment(null)} />
      <ModalCreateComment
        attractionId={attraction._id!}
        visible={showModalCreateComment}
        onClose={() => setShowModalCreateComment(false)}
        onAdd={onAddComment}
      />
    </div>
  );
};

export default styled(ContributionList)`
  margin: 35px 0;

  .container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .card {
    display: flex;
    align-items: center;
    width: calc(50% - 5px);
    height: 290px;
    margin: 5px 0;
    padding: 10px;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &:nth-of-type(odd) {
      margin-right: 5px;
    }
    &:nth-of-type(even) {
      margin-left: 5px;
    }

    @media only screen and (max-width: 400px) {
      width: 100%;
      margin: 5px 0 !important;
    }

    h3 {
      margin: 0;
    }

    &.new {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
`;

const GET_COMMENTS = gql`
  query Comments($attractionId: ID!, $skip: Int) {
    comments(input: { attractionId: $attractionId, skip: $skip }) {
      _id
      text
      nickname
      image
      hidden
    }
  }
`;

const COMMENTS_SUBSCRIPTION = gql`
  subscription OnCommentAdded($attractionId: ID!) {
    commentAdded(attractionId: $attractionId) {
      _id
      text
      nickname
      image
      hidden
    }
  }
`;

const HIDE_COMMENT = gql`
  mutation HideComment($id: ID!, $hidden: Boolean!) {
    hideComment(input: { id: $id, hidden: $hidden })
  }
`;
