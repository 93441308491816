import React from 'react';
import { IComment } from '@/types/comment.model';
import moment from 'moment';
import styled from 'styled-components';
import { DisconnectOutlined } from '@ant-design/icons';

interface Props {
  className?: string;
  comment: Partial<IComment>;
  onSeeComment?: (commentId: string) => void;
}

const CommentCardContent = React.forwardRef<unknown, Props>((props, ref) => {
  const { className, comment, onSeeComment, ...others } = props;
  if (comment.hidden) {
    return (
      <div ref={ref as any} className={`${className} card hidden`}>
        <div className="top">
          {/* <div className="theme">
            <span className={`dot ${comment.theme}`} /> {comment.theme}
          </div> */}
          <div className="date">{moment(comment.createdAt!).format('L')}</div>
        </div>
        <div className="comment">
          <DisconnectOutlined />
        </div>
        <span className="has-been-hidden">{`Caché par l'organisateur`}</span>
      </div>
    );
  }
  if (comment.image) {
    return (
      <div
        ref={ref as any}
        className={`${className} card with-image`}
        {...others}
        onClick={onSeeComment ? () => onSeeComment(comment._id!) : undefined}
      >
        <div className="image" style={{ backgroundImage: `url("${comment.image}")` }}>
          <div className="bottom" />
          <div className="date">{moment(comment.createdAt!).format('L')}</div>
        </div>
        <div className="comment">
          <p>{comment.text}</p>
        </div>
        <span className="author">{comment.nickname}</span>
        {/* <div className="theme">
          <span className="dot" /> {comment.theme}
        </div> */}
      </div>
    );
  } else {
    return (
      <div
        ref={ref as any}
        className={`${className} card`}
        {...others}
        onClick={onSeeComment ? () => onSeeComment(comment._id!) : undefined}
      >
        <div className="top">
          <div className="date">{moment(comment.createdAt!).format('L')}</div>
        </div>
        <div className="comment">
          <p>{comment.text}</p>
        </div>
        <span className="author">{comment.nickname}</span>
      </div>
    );
  }
});

CommentCardContent.displayName = 'CommentCardContent';

export default styled(CommentCardContent)`
  &.card {
    .top {
      display: flex;
      position: absolute;
      top: 15px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      left: 0;
      padding: 0 15px;
      .date {
        color: #9b9b9b;
      }
    }

    .comment {
      width: 100%;
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 18px;
        padding: 0;
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
        text-align: center;
      }
    }

    .author {
      width: 100%;
      font-style: italic;
      color: #9b9b9b;
    }

    .theme {
      border-radius: 20px;
      background: black;
      color: white;
      padding: 2px 10px 2px 8px;
      font-size: 12px;
      top: 20px;
      left: 20px;
      display: flex;
      align-items: center;

      .dot {
        display: inline-block;
        background-color: green;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        margin-right: 5px;

        &.NATURE {
          background-color: #7ec72a;
        }
        &.MOBILITY {
          background-color: #4a90e2;
        }
        &.LIFE {
          background-color: #e69c2a;
        }
      }
    }
  }

  &.card.with-image {
    text-align: left;
    justify-content: flex-start;
    .author {
      text-align: right;
    }
    .comment {
      p {
        font-size: 14px;
        line-height: 18px;
        -webkit-line-clamp: 2;
        text-align: left;
        margin: 5px 0 10px;
      }
    }

    .theme {
      position: absolute;
    }

    .image {
      background-position: center;
      background-size: cover;
      border-radius: 4px;
      width: 100%;
      height: 190px;
      position: relative;
      overflow: hidden;

      .bottom {
        background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
      }

      .date {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: white;
      }
    }
  }

  &.card.hidden {
    justify-content: center;
    align-items: center;
    .anticon {
      font-size: 64px;
    }
    .has-been-hidden {
      margin-top: 10px;
      color: gray;
    }
  }
`;
