import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import WalkRoute from './walk';
import Walks from './Walks';

const WalksRoute: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Walks />
      </Route>
      <Route path={`${path}/:walkId`}>
        <WalkRoute />
      </Route>
    </Switch>
  );
};

export default WalksRoute;
