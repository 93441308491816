import { apolloClient } from '@/apollo';
import { gql } from '@apollo/client';
import { Button, Form, Input, message, Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const SIGN_UP = gql`
  mutation SignUp($email: String!, $password: String!, $name: String!) {
    signUp(input: { email: $email, password: $password, name: $name }) {
      _id
      email
    }
  }
`;

const ModalSignUp: React.FC<Props> = ({ className, visible, onClose }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values: any) => {
    const { password, name, email } = values;
    apolloClient
      .mutate({
        mutation: SIGN_UP,
        variables: {
          email,
          password,
          name,
        },
      })
      .then(() => {
        form.resetFields();
        history.push('/check-your-email');
        onClose();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      style={{
        maxWidth: '100%',
      }}
    >
      <Form className={className} form={form} onFinish={onFinish} size="large" layout="vertical">
        <h1>Inscription</h1>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item name="password" label="Mot de passe" rules={[{ required: true }]}>
          <Input
            type="password"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            title="Le mot de passe doit comporter au moins 8 caractères et doit comporter au moins une majuscule, un nombre et une minuscule"
          />
        </Form.Item>
        <Form.Item
          name="password2"
          label="Confirmation du mot de passe"
          rules={[
            {
              required: true,
              validator: (_rule, value) => {
                const password = form.getFieldValue('password');
                if (password === value) {
                  return Promise.resolve(value);
                } else {
                  return Promise.reject('error');
                }
              },
              validateTrigger: 'onBlur',
              message: `Les mots de passe ne sont pas identiques`,
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item name="name" label="Pseudo" rules={[{ required: true, min: 5, max: 20 }]}>
          <Input type="text" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            {`S'inscrire`}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalSignUp)`
  padding: 25px 20px 15px;
  form {
    margin: 0 auto;
    max-width: 400px;
  }
`;
