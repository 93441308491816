import { Result } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckEmailIllustration } from '../svg/check-email.svg';

interface Props {
  className?: string;
}

const CheckYourEmailPage: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Result
        icon={<CheckEmailIllustration id="check-email-illustration" style={{ maxWidth: 250 }} />}
        title="Confirm your email!"
        subTitle="Your account has been successfully registered. To complete the process please check your email for a validation request."
      />
    </div>
  );
};

export default styled(CheckYourEmailPage)`
  max-width: 550px;
  margin: 0 auto;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;

  #check-email-illustration {
    width: 100%;
    max-width: 210px;
    g:last-child() {
      opacity: 0;
      animation: dropIn 2s ease forwards;
      &:nth-of-type(1) {
        animation-delay: calc(0ms);
      }
      &:nth-of-type(2) {
        animation-delay: calc(200ms);
      }
      &:nth-of-type(3) {
        animation-delay: calc(400ms);
      }
    }
  }

  @keyframes dropIn {
    20% {
      opacity: 0;
      transform: translateY(-20%);
    }
    30%,
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;
