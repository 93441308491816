import { Avatar, Button, Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import { ColorSwitch16 } from '@carbon/icons-react';

interface Props {
  className?: string;
  email: string | null;
  logout: () => void;
}

const MenuUser: React.FC<Props> = (props) => {
  const { className, email, logout, ...otherProps } = props;
  return (
    <Menu className={className} {...otherProps}>
      <Menu.Item>
        <div>
          <Avatar size="small" icon={<UserOutlined />} /> {email}
        </div>
        {/* <NavLink activeClassName="is-active" to="/account">
          Mon compte
        </NavLink> */}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout} style={{ color: 'red' }}>
        Déconnexion
      </Menu.Item>
    </Menu>
  );
};

export default styled(MenuUser)`
  li a {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
