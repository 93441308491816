import { Form, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';

import RangeSlider from './RangeSlider';
import styled from 'styled-components';
import { apolloClient } from '@/apollo';

interface Props {
  className?: string;
  visible: boolean;
  attractionId: string;
  onClose: () => void;
  // onSave?: (walk: Partial<IWalk>) => void;
}

const initialValues = {
  present: {
    nature: '4',
    mobility: '4',
    life: '4',
    identity: '4',
    health: '4',
    security: '4',
  },
  future: {
    nature: '4',
    mobility: '4',
    life: '4',
    identity: '4',
    health: '4',
    security: '4',
  },
};

const ModalCreatePerception: React.FC<Props> = ({ className, visible, attractionId, onClose }) => {
  const [form] = useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...initialValues,
      });
    }
  }, [visible]);

  const onFinish = (values: any) => {
    // Cast values in number instead of string
    for (const i in values.present) {
      values.present[i] = +values.present[i];
      values.future[i] = +values.future[i];
    }

    setIsSaving(true);
    apolloClient
      .mutate({
        mutation: ADD_PERCEPTION,
        variables: {
          input: {
            ...values,
            attractionId,
          },
        },
      })
      .then(() => {
        setIsSaving(false);
        onClose();
      })
      .catch((error) => {
        message.error(error.message);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      className={className}
      visible={visible}
      onCancel={onClose}
      onOk={() => form.submit()}
      confirmLoading={isSaving}
      okText="Envoyer"
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{ ...initialValues }}>
        <h2>Quel est votre ressenti aujourd’hui ?</h2>
        <Form.Item name={['present', 'nature']} label="Nature">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['present', 'mobility']} label="Mobilité">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['present', 'life']} label="Vie de quartier">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['present', 'identity']} label="Identité">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['present', 'health']} label="Santé">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['present', 'security']} label="Securité">
          <RangeSlider />
        </Form.Item>
        <h2>Quel est votre désir pour demain ?</h2>
        <Form.Item name={['future', 'nature']} label="Nature">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['future', 'mobility']} label="Mobilité">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['future', 'life']} label="Vie de quartier">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['future', 'identity']} label="Identité">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['future', 'health']} label="Santé">
          <RangeSlider />
        </Form.Item>
        <Form.Item name={['future', 'security']} label="Securité">
          <RangeSlider />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalCreatePerception)`
  h2 {
    margin-top: 20px;
  }
  .ant-form-item-label {
    padding: 0;
    label {
      /* font-size: 16px; */
      font-weight: bold;
      color: #546396;
    }
  }
`;

const ADD_PERCEPTION = gql`
  mutation AddPerception($input: AddPerceptionInput) {
    addPerception(input: $input)
  }
`;
