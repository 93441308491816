import { apolloClient } from '@/apollo';
import { gql } from '@apollo/client';
import { Button, Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const VerifyYourEmailPage: React.FC<Props> = ({ className }) => {
  const location = useLocation();
  const [verifying, setVerifying] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    const regex = /(?:token\=)([^&]*)/;
    const token = regex.exec(window.location?.search || '')?.[1] || '';
    if (token) {
      apolloClient
        .query({
          query: VERIFY_EMAIL,
          variables: {
            token,
          },
        })
        .then(() => {
          setVerified(true);
          setVerifying(false);
        });
    } else {
      setVerifying(false);
    }
  }, [location]);

  return (
    <div className={className}>
      {verifying && (
        <Result
          icon={<Spin spinning={true} size="large" />}
          title="Verifying your email ..."
          subTitle="Your email is being verified, it should just take few seconds."
        />
      )}
      {!verifying && verified && (
        <Result
          icon={<img src={`${process.env.PUBLIC_URL}/img/email-verified.svg`} style={{ maxWidth: 250 }} />}
          title="Your email is verified!"
          subTitle="You can now login and enjoy fully Unlimited Walks."
        />
      )}
      {!verifying && !verified && (
        <Result
          status="error"
          title="Your token is invalid!"
          subTitle="You didn't open the correct link in your email or you token has expired. Please contact support if you have any questions."
          extra={[
            <Button
              type="primary"
              key="support"
              onClick={() => {
                window.location.href = 'mailto:support@unlimitedwalks.org';
              }}
            >
              Contact Support
            </Button>,
          ]}
        />
      )}
    </div>
  );
};

export default styled(VerifyYourEmailPage)`
  max-width: 550px;
  margin: 0 auto;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VERIFY_EMAIL = gql`
  query VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;
