import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

interface Props {
  className?: string;
  isLoading: boolean;
  onReach: () => void;
}

const InfiniteScroll: React.FC<Props> = ({ className, isLoading, onReach }) => {
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !isLoading) {
      onReach();
    }
  }, [inView, isLoading]);

  return (
    <div className={className} style={{ height: 200 }} ref={ref}>
      <Spin spinning={isLoading} size="large" />
    </div>
  );
};

export default styled(InfiniteScroll)`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
