import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { IWalk } from '@/types/walk.model';
import WalkPopup from './WalkPopup';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

interface Props {
  className?: string;
  walks: Partial<IWalk>[];
}

const MapWithWalks: React.FC<Props> = ({ className, walks }) => {
  const mapContainer = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;
  const history = useHistory();

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current!,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [2.3488, 48.8534],
      zoom: 9,
    });

    // Add a new source from our GeoJSON data and
    // set the 'cluster' option to true. GL-JS will
    // add the point_count property to your source data.

    const features = walks?.map((walk, index) => {
      const { name, center, _id } = walk;
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: center,
        },
        properties: {
          title: name,
          index: index + 1,
          _id,
        },
      };
    });

    map.on('load', () => {
      map.loadImage('/img/marker-walk.png', (error: any, image: any) => {
        if (error) throw error;

        // Add the image to the map style.
        map.addImage('my-marker', image);
        map.addSource('walks', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features,
          },
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'walks',
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          },
        });

        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'walks',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });

        map.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'walks',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'text-color': '#ffffff',
          },
          layout: {
            'icon-image': 'my-marker',
            'icon-size': 0.33,
          },
        });

        // inspect a cluster on click
        map.on('click', 'clusters', (e: any) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          const clusterId = features[0].properties.cluster_id;
          map.getSource('walks').getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        map.on('click', 'unclustered-point', (e: any) => {
          const coordinates = e.features[0].geometry.coordinates.slice();

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const placeholder = document.createElement('div');
          const walk = walks.find((x) => x._id === e.features[0].properties._id);
          if (walk) {
            ReactDOM.render(<WalkPopup walk={walk} onClick={() => history.push(`/walks/${walk._id}`)} />, placeholder);
            new mapboxgl.Popup().setLngLat(coordinates).setDOMContent(placeholder).addTo(map);
          }
        });

        map.on('mouseenter', 'clusters', function () {
          map.getCanvas().style.cursor = 'pointer';
        });
        map.on('mouseleave', 'clusters', function () {
          map.getCanvas().style.cursor = '';
        });

        if (features?.length) {
          const bounds = new mapboxgl.LngLatBounds();

          features.forEach((feature) => {
            if (feature.geometry.coordinates?.length) {
              bounds.extend(feature.geometry.coordinates);
            }
            // new mapboxgl.Marker({ color: '#546396' }).setLngLat(feature.geometry?.coordinates).addTo(map);
          });

          map.fitBounds(bounds, { maxZoom: 16 });
        }
      });
    });

    return () => map.remove();
  }, []);

  return <div className={className} ref={mapContainer} />;
};

export default styled(MapWithWalks)`
  z-index: 1;
  position: absolute;
  top: 60px;
  width: 100vw;
  height: calc(100vh - 60px);
`;
