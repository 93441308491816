import React from 'react';
import styled from 'styled-components';

interface OnChangeHandler {
  (e: any): void;
}

interface Props {
  className?: string;
  value?: number;
  onChange?: OnChangeHandler;
}

const RangeSlider: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(({ className, value, onChange }, ref) => {
  return (
    <div className={className} ref={ref!}>
      <input type="range" min={1} max={7} value={value} onChange={onChange} />
      <div className="description">
        <span>peu</span>
        <span>beaucoup</span>
      </div>
    </div>
  );
});

RangeSlider.displayName = 'RangeSlider';

export default styled(RangeSlider)`
  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: 40px;
    background: #ededed;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 2px;
    padding: 2px;
    margin-top: 5px;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 36px;
      background: #fff;
      cursor: pointer;
      border: none;
      border-radius: 2px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 36px;
      background: #fff;
      cursor: pointer;
      border: none;
      border-radius: 2px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }
  }
  .description {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    color: #9b9b9b;
    padding: 5px;
  }
`;
