import { IWalk } from '@/types/walk.model';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  walk: Partial<IWalk>;
  onClick: () => void;
}

const WalkPopup: React.FC<Props> = ({ walk, className, onClick }) => {
  return (
    <div className={className}>
      <h2>{walk.name}</h2>
      <span>@{walk.author?.name}</span>
      <br />
      <Button size="small" type="primary" onClick={onClick}>
        Voir
      </Button>
    </div>
  );
};

export default styled(WalkPopup)`
  width: 200px;
  h2 {
    margin-bottom: 0;
  }

  button {
    margin-top: 5px;
    width: 100%;
  }
`;
