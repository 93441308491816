import { EarthFilled16, Map16 } from '@carbon/icons-react';
import { Button, Menu, Avatar } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  isAuthentificated: boolean;
  email: string | null;
  logout: () => void;
  setShowModalLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalSignUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuMobile: React.FC<Props> = (props) => {
  const { className, isAuthentificated, email, logout, setShowModalLogin, setShowModalSignUp, ...otherProps } = props;
  const { i18n, t } = useTranslation();
  return (
    <Menu className={className} {...otherProps}>
      <Menu.Item>
        <NavLink activeClassName="is-active" to="/explore">
          <EarthFilled16 /> Explorer
        </NavLink>
      </Menu.Item>
      {isAuthentificated && (
        <Menu.Item>
          <NavLink activeClassName="is-active" to="/app/walks">
            <Map16 />
            Mes ballades
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Divider />
      <SubMenu title={LANGUAGES[i18n.language]}>
        <Menu.Item onClick={() => i18n.changeLanguage('fr')}>Français</Menu.Item>
        <Menu.Item onClick={() => i18n.changeLanguage('en')}>English</Menu.Item>
      </SubMenu>
      <Menu.Divider />
      {!isAuthentificated && (
        <React.Fragment>
          <Menu.Item>
            <Button onClick={() => setShowModalLogin(true)}>Se connecter</Button>
          </Menu.Item>
          <Menu.Item>
            <Button type="primary" onClick={() => setShowModalSignUp(true)}>{`S'inscrire`}</Button>
          </Menu.Item>
        </React.Fragment>
      )}
      {isAuthentificated && (
        <React.Fragment>
          <Menu.Item>
            <div>
              <Avatar size="small" icon={<UserOutlined />} /> {email}
            </div>
            {/* <NavLink activeClassName="is-active" to="/app/account">
              Mon compte
            </NavLink> */}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <Button type="link" onClick={logout} style={{ color: 'red' }}>
              Déconnexion
            </Button>
          </Menu.Item>
        </React.Fragment>
      )}
    </Menu>
  );
};

export default styled(MenuMobile)`
  li a {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;

const LANGUAGES: { [key: string]: string } = {
  fr: 'Français',
  en: 'English',
};
