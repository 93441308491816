import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import AttractionRoute from './attraction';
import Walk from './Walk';

const WalkRoute: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Walk />
      </Route>
      <Route path={`${path}/:attractionIndex`}>
        <AttractionRoute />
      </Route>
    </Switch>
  );
};

export default WalkRoute;
