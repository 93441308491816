import { IWalk } from '@/types/walk.model';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@/redux/hooks';
import { Add32 } from '@carbon/icons-react';
import AttractionCard from './AttractionCard';
import { IAttraction } from '@/types/attraction.model';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apolloClient } from '@/apollo';
import { gql } from '@apollo/client';
import ModalAttraction from './ModalAttraction';

const { confirm } = Modal;

interface Props {
  className?: string;
  walk: Partial<IWalk>;
  onAddAttraction: (attraction: Partial<IAttraction>) => void;
  onUpdateAttraction: (attraction: Partial<IAttraction>) => void;
  onDeleteAttraction: (id: string) => void;
}

const AttractionCardList: React.FC<Props> = ({
  className,
  walk,
  onAddAttraction,
  onUpdateAttraction,
  onDeleteAttraction,
}) => {
  const myUserId = useAppSelector((state) => state.auth.userId);
  const [currentAttraction, setCurrentAttraction] = useState<Partial<IAttraction> | null>(null);

  const onWantDelete = (id: string) => {
    confirm({
      title: 'Are you sure delete this attraction?',
      icon: <ExclamationCircleOutlined />,
      content: 'This operation can not be undone and you will loose all the data.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return apolloClient
          .mutate({
            mutation: DELETE_ATTRACTION,
            variables: {
              id,
            },
          })
          .then((response) => {
            message.info(`L'étape a bien été supprimée !`);
            onDeleteAttraction(id);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  };

  const onWantUpdate = (attraction: Partial<IAttraction>) => {
    setCurrentAttraction(attraction);
  };

  const editable = walk.author?._id === myUserId;

  return (
    <div className={className}>
      {walk.attractions?.map((attraction, index) => (
        <AttractionCard
          key={attraction._id}
          attraction={attraction}
          position={index + 1}
          onWantDelete={editable ? onWantDelete : undefined}
          onWantUpdate={editable ? onWantUpdate : undefined}
        />
      ))}
      {editable && (
        <React.Fragment>
          <div className="card new" style={{ color: '#343A4F' }} onClick={() => setCurrentAttraction({})}>
            <Add32 /> Créer une nouvelle étape
          </div>
          <ModalAttraction
            walkId={walk._id!}
            attraction={currentAttraction}
            onClose={() => setCurrentAttraction(null)}
            onAdd={onAddAttraction}
            onUpdate={onUpdateAttraction}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default styled(AttractionCardList)`
  margin: 35px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
    }

    &.error {
      cursor: default;
    }

    &.new {
      align-items: center;
      justify-content: center;
    }
  }
`;

const DELETE_ATTRACTION = gql`
  mutation DeleteAttraction($id: ID!) {
    deleteAttraction(id: $id)
  }
`;
