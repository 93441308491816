import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Error404 from './components/Error404';
import './App.less';
import './App.scss';
import AppPages from './pages/app';
import ScrollToTop from './components/ScrollToTop';
import { ConfigProvider } from 'antd';
import ExplorePage from './pages/app/explore';
import WalksRoute from './pages/app/walks';
import CheckYourEmailPage from './pages/CheckYourEmailPage';
import VerifyYourEmail from './pages/VerifyYourEmail';

const App: React.FC = () => {
  return (
    <ConfigProvider>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" render={() => <Redirect to={`/explore`} />} />
        <Route exact path="/explore">
          <ExplorePage />
        </Route>
        <Route path="/walks">
          <WalksRoute />
        </Route>
        <PrivateRoute path="/app" component={AppPages} />
        <Route path="/check-your-email">
          <CheckYourEmailPage />
        </Route>
        <Route path="/verify-your-email">
          <VerifyYourEmail />
        </Route>
        <Route component={() => <Error404 />} />
      </Switch>
    </ConfigProvider>
  );
};

export default App;
