import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ConfigProvider, Spin } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import enUS from 'antd/es/locale/en_US';
import { I18nextProvider, useTranslation } from 'react-i18next';
import './i18n';
import i18n from './i18n';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './apollo';

type TLanguages = 'fr' | 'en';

const languages = {
  fr: frFR,
  en: enUS,
};

const Root: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ConfigProvider locale={languages[language as TLanguages]}>
          <ApolloProvider client={apolloClient}>
            <Provider store={store}>
              <App />
            </Provider>
          </ApolloProvider>
        </ConfigProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

ReactDOM.render(
  //<React.StrictMode>
  <Suspense fallback={<Spin spinning={true} style={{ width: '100%', height: '100vh' }} />}>
    <Root />
  </Suspense>,
  //</React.StrictMode>,
  document.getElementById('root'),
);
