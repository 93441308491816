import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { IAttraction } from '@/types/attraction.model';
import Perception from './Perception';

interface Props {
  className?: string;
  perception: {
    num_perception: number;
    future_perception: IAttraction['present_perception'];
    present_perception: IAttraction['future_perception'];
  } | null;
  onClose: () => void;
  onSeeComment?: (commentId: string) => void;
}

const ModalPerception: React.FC<Props> = ({ className, perception, onClose }) => {
  return (
    <Modal
      visible={!!perception}
      onCancel={onClose}
      className={className}
      width={550}
      footer={null}
      style={{
        maxWidth: '100%',
      }}
    >
      {perception && <Perception perception={perception} size="large" />}
    </Modal>
  );
};

export default styled(ModalPerception)``;
