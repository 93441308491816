import { Button, Form, Input, message, Modal, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { apolloClient } from '@/apollo';
import { IAttraction } from '@/types/attraction.model';
import { FileImageOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styled from 'styled-components';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  'pk.eyJ1IjoiYW5jZWxuaWNvbGFzIiwiYSI6ImNqbXVsZnUybTJwbmczcW9leGF4eW1wOW8ifQ.GbtiLEXru7Iop8TkB4wSpw';

interface Props {
  className?: string;
  walkId: string;
  attraction: Partial<IAttraction> | null;
  onClose: () => void;
  onAdd: (attraction: Partial<IAttraction>) => void;
  onUpdate: (attraction: Partial<IAttraction>) => void;
}

const ModalAttraction: React.FC<Props> = ({ className, walkId, attraction, onClose, onAdd, onUpdate }) => {
  const [form] = useForm();
  const mapContainer = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;
  const [lng, setLng] = useState<number | null>(null);
  const [lat, setLat] = useState<number | null>(null);
  const fileRef = useRef<any>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (attraction) {
      form.resetFields();
      setImageUrl(null);
      fileRef.current = null;

      let imageUrl = null;
      let lat = null;
      let lng = null;

      if (attraction._id) {
        form.setFieldsValue({ ...attraction });
        imageUrl = attraction.image || null;
        [lng = null, lat = null] = attraction.location?.coordinates || [];
      }

      const map = new mapboxgl.Map({
        container: mapContainer.current!,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: lat && lng ? [lng, lat] : [4.35171, 50.85034],
        zoom: 9,
      });

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
        flyTo: {
          speed: 2,
        },
      });

      let marker: any = null;

      const addMarker = (position: number[]) => {
        if (marker) {
          marker.remove();
        }
        marker = new mapboxgl.Marker({ draggable: true, color: '#546396' }).setLngLat(position).addTo(map);
        setLng(position[0]);
        setLat(position[1]);
        marker.on('dragend', () => {
          const lngLat = marker.getLngLat();
          setLng(lngLat.lng);
          setLat(lngLat.lat);
        });
      };

      geocoder.on('result', function (e) {
        addMarker(e.result.center);
      });

      map.on('click', (e: any) => {
        const { lng, lat } = e.lngLat;
        addMarker([lng, lat]);
      });

      map.addControl(geocoder);

      setImageUrl(imageUrl);
      setLat(lat);
      setLng(lng);

      if (lat && lng) {
        addMarker([lng, lat]);
      }

      setTimeout(() => {
        map.resize();
      }, 0);

      return () => {
        map.remove();
      };
    }
  }, [attraction]);

  const pictureCustomRequest = async (options: any) => {
    const { onSuccess, file, onError } = options;
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });

      fileRef.current = compressedFile;
      setImageUrl(URL.createObjectURL(file));
      onSuccess('done', file);
    } catch (error) {
      console.log(error);
      message.error('File is too big.');
      return;
    }
  };

  const onPictureRemove = async (file: any) => {
    return new Promise<boolean>((resolve) => {
      setImageUrl(null);
      resolve(true);
    });
  };

  const onFinish = (values: any) => {
    if (!attraction?._id) {
      setIsSaving(true);
      apolloClient
        .mutate({
          mutation: ADD_ATTRACTION,
          variables: {
            ...values,
            lat,
            lng,
            walkId,
            upload: fileRef.current,
          },
        })
        .then((response) => {
          setIsSaving(false);
          onAdd(response?.data?.addAttraction);
          onClose();
        })
        .catch((error) => {
          message.error(error.message);
          setIsSaving(false);
        });
    } else {
      setIsSaving(true);
      apolloClient
        .mutate({
          mutation: UPDATE_ATTRACTION,
          variables: {
            ...values,
            id: attraction._id,
            lat,
            lng,
            upload: fileRef.current,
            hasImage: !!imageUrl,
          },
        })
        .then((response) => {
          setIsSaving(false);
          onUpdate(response?.data?.updateAttraction);
          onClose();
        })
        .catch((error) => {
          message.error(error.message);
          setIsSaving(false);
        });
    }
  };

  return (
    <Modal
      title={attraction?._id ? `Modifier l'étape` : 'Créer une nouvelle étape'}
      visible={!!attraction}
      onCancel={onClose}
      onOk={() => form.submit()}
      okText="Valider"
      confirmLoading={isSaving}
      className={className}
      width={800}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item label="Image">
          <Upload
            name="attraction-upload"
            listType="picture-card"
            className="avatar-uploader"
            multiple={false}
            showUploadList={false}
            accept="image/*"
            customRequest={pictureCustomRequest}
            onRemove={onPictureRemove}
          >
            {imageUrl ? (
              <div
                style={{
                  backgroundImage: `url("${imageUrl}")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '100%',
                  width: '100%',
                }}
              />
            ) : (
              <div>
                <FileImageOutlined style={{ fontSize: 36, marginBottom: 10 }} />
                <br />
                Ajouter une image
              </div>
            )}
          </Upload>
        </Form.Item>
        {imageUrl && (
          <Button
            type="primary"
            danger
            style={{ marginBottom: 20, marginTop: -20, width: '100%' }}
            onClick={onPictureRemove}
          >
            {`Supprimer l'image`}
          </Button>
        )}
        <Form.Item name="title" label="Titre" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
          <Input.TextArea rows={8} />
        </Form.Item>
        <div className="map-container" ref={mapContainer} />
        {(!lng || !lat) && <p>*Vous devez indiquez une position sur la carte pour enregistrer votre étape</p>}
      </Form>
    </Modal>
  );
};

export default styled(ModalAttraction)`
  width: 100%;
  margin: 0 auto;
  max-width: 590px;
  padding: 55px 20px;

  .map-container {
    position: relative;
    left: 0;
    width: 100%;
    height: 345px;
    margin-bottom: 20px;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
    height: 345px;
  }
`;

const ADD_ATTRACTION = gql`
  mutation AddAttraction(
    $title: String!
    $description: String!
    $lat: Float!
    $lng: Float!
    $walkId: ID!
    $upload: Upload
  ) {
    addAttraction(
      input: { title: $title, description: $description, lat: $lat, lng: $lng, upload: $upload, walkId: $walkId }
    ) {
      _id
      title
      image
      description
      location {
        type
        coordinates
      }
    }
  }
`;

const UPDATE_ATTRACTION = gql`
  mutation UpdateAttraction(
    $id: ID!
    $title: String!
    $description: String!
    $lat: Float!
    $lng: Float!
    $upload: Upload
    $hasImage: Boolean!
  ) {
    updateAttraction(
      input: {
        id: $id
        title: $title
        description: $description
        lat: $lat
        lng: $lng
        upload: $upload
        hasImage: $hasImage
      }
    ) {
      _id
      title
      image
      description
      location {
        type
        coordinates
      }
    }
  }
`;
