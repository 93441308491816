import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Chart, { Tick } from 'chart.js/auto';
import { IAttraction } from '@/types/attraction.model';

interface Props {
  className?: string;
  perception: {
    num_perception: number;
    future_perception: IAttraction['present_perception'];
    present_perception: IAttraction['future_perception'];
  };
  size?: 'small' | 'large';
}

const notations: { [key: number]: string } = {
  1: 'peu',
  2: 'peu',
  4: 'moyen',
  7: 'beaucoup',
};
const Perception: React.FC<Props> = ({ className, perception, size = 'small' }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    const { num_perception, present_perception, future_perception } = perception;
    const data = {
      labels:
        size === 'large'
          ? Object.keys(present_perception!)
          : new Array(Object.keys(present_perception!).length).fill(''),
      datasets: [
        {
          label: 'Present',
          data: Object.values(present_perception!).map((x) => x / num_perception!),
          fill: true,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Future',
          data: Object.values(future_perception!).map((x) => x / num_perception!),
          fill: true,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)',
        },
      ],
    };

    const chartInstance = new Chart(chartContainer.current!, {
      type: 'radar',
      data: data,
      options: {
        scales: {
          r: {
            min: 1,
            max: 7,
            grid: {
              color: 'red',
              display: false,
            },
            ticks: {
              //min: 1,
              //max: 7,
              maxTicksLimit: size === 'large' ? 7 : 3,
              showLabelBackdrop: false,
              callback: function (value: number | string, index: number, ticks: Tick[]) {
                if (size === 'large') {
                  return notations[+value];
                } else {
                  return '';
                }
              },
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: size === 'large' ? true : false,
          },
        },
        elements: {
          line: {
            borderWidth: 3,
          },
        },
      },
    });
    setTimeout(() => {
      chartInstance.update();
    }, 0);

    return () => {
      chartInstance?.destroy();
    };
  }, [perception]);

  return (
    <div className={className}>
      <div className="chart-container" style={{ position: 'relative', width: '100%', height: '100%' }}>
        <canvas ref={chartContainer} />
      </div>
      {size === 'large' && <span>{`${perception.num_perception} participation(s)`}</span>}
    </div>
  );
};

export default styled(Perception)`
  span {
    display: block;
    color: gray;
    text-align: right;
    font-style: italic;
  }
`;
