import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Attraction from './Attraction';
import Comment from './comment/Comment';

const AttractionRoute: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Attraction />
      </Route>
      <Route path={`${path}/:attractionId/:commentId`}>
        <Comment />
      </Route>
    </Switch>
  );
};

export default AttractionRoute;
