import { IWalk } from '@/types/walk.model';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@/redux/hooks';
import { gql, useQuery } from '@apollo/client';
import { message, Modal, Result } from 'antd';
import { Add32 } from '@carbon/icons-react';
import WalkCard from './WalkCard';
import ModalWalk from '@/components/ModalWalk';
import WalkCardLoading from './WalkCardLoading';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apolloClient } from '@/apollo';
const { confirm } = Modal;

interface Props {
  className?: string;
  userId: string;
}

const WalkCardList: React.FC<Props> = ({ className, userId }) => {
  const myUserId = useAppSelector((state) => state.auth.userId);
  const [walks, setWalks] = useState<Partial<IWalk>[]>([]);
  const [currentWalk, setCurrentWalk] = useState<Partial<IWalk> | null>(null);

  const { loading, error } = useQuery(GET_WALKS, {
    variables: {
      userId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setWalks(data.walksByUserId);
    },
  });

  const onAddWalk = (walk: Partial<IWalk>) => {
    setWalks([...walks, walk]);
  };

  const onUpdateWalk = (walk: Partial<IWalk>) => {
    const updatedWalks = [...walks];
    const walkIndex = updatedWalks.findIndex((x) => x._id === walk._id);
    if (~walkIndex) {
      updatedWalks[walkIndex] = walk;
    }
    setWalks(updatedWalks);
  };

  const onWantDelete = (id: string) => {
    confirm({
      title: 'Are you sure delete this walk?',
      icon: <ExclamationCircleOutlined />,
      content: 'This operation can not be undone and you will loose all the data.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return apolloClient
          .mutate({
            mutation: DELETE_WALK,
            variables: {
              id,
            },
          })
          .then(() => {
            message.info('La ballade a bien été supprimée !');
            const updatedWalks = [...walks];
            const walkIndex = updatedWalks.findIndex((x) => x._id === id);
            if (~walkIndex) {
              updatedWalks.splice(walkIndex, 1);
            }
            setWalks(updatedWalks);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  };

  const onWantUpdate = (walk: Partial<IWalk>) => {
    setCurrentWalk(walk);
  };

  const editable = userId === myUserId;

  return (
    <div className={className}>
      {loading && <WalkCardLoading />}
      {error && (
        <div className="card error">
          <Result status="warning" title="Les ballades n'ont pas pu être récupérées." />
        </div>
      )}
      {walks.map((walk: Partial<IWalk>) => (
        <WalkCard
          key={walk._id}
          walk={walk}
          onWantDelete={editable ? onWantDelete : undefined}
          onWantUpdate={editable ? onWantUpdate : undefined}
        />
      ))}
      {userId === myUserId && (
        <React.Fragment>
          <div className="card new" style={{ color: '#343A4F' }} onClick={() => setCurrentWalk({})}>
            <Add32 /> Créer une nouvelle ballade
          </div>
          <ModalWalk
            walk={currentWalk}
            onClose={() => setCurrentWalk(null)}
            onAdd={onAddWalk}
            onUpdate={onUpdateWalk}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default styled(WalkCardList)`
  margin: 35px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.error {
      cursor: default;
    }

    &.new {
      align-items: center;
      justify-content: center;
    }
  }
`;

const GET_WALKS = gql`
  query WalksByUserId($userId: ID!) {
    walksByUserId(userId: $userId) {
      _id
      name
      createdAt
      author {
        _id
        name
      }
    }
  }
`;

const DELETE_WALK = gql`
  mutation DeleteWalk($id: ID!) {
    deleteWalk(id: $id)
  }
`;
