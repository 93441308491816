import React, { useState } from 'react';
import { IComment } from '@/types/comment.model';
import { Dropdown, Menu } from 'antd';
import CommentCardContent from './CommentCardContent';

interface Props {
  comment: Partial<IComment>;
  onWantHide?: (id: string) => void;
  onSeeComment?: (commentId: string) => void;
}

const ContextMenu: React.FC<{
  id: string;
  onWantHide: (id: string) => void;
  close: () => void;
}> = ({ id, onWantHide, close }) => {
  return (
    <Menu onClick={close}>
      {!!onWantHide && (
        <Menu.Item key="update" onClick={() => onWantHide(id)}>
          Cacher
        </Menu.Item>
      )}
    </Menu>
  );
};

const CommentCard: React.FC<Props> = ({ comment, onSeeComment, onWantHide }) => {
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  if (!!onWantHide) {
    return (
      <Dropdown
        overlay={() => (
          <ContextMenu
            id={comment._id!}
            onWantHide={onWantHide}
            close={() => {
              setShowContextMenu(false);
            }}
          />
        )}
        trigger={['contextMenu']}
        visible={showContextMenu}
        onVisibleChange={(e) => {
          setShowContextMenu(e);
        }}
      >
        <CommentCardContent comment={comment} onSeeComment={onSeeComment} />
      </Dropdown>
    );
  } else {
    return <CommentCardContent comment={comment} onSeeComment={onSeeComment} />;
  }
};

export default CommentCard;
