import { apolloClient } from '@/apollo';
import { setAccessToken } from '@/redux/authSlice';
import { useAppDispatch } from '@/redux/hooks';
import { gql } from '@apollo/client';
import { Button, Form, Input, message, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
    }
  }
`;

const ModalLogin: React.FC<Props> = ({ className, visible, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    apolloClient
      .query({
        query: LOGIN,
        variables: {
          ...values,
        },
      })
      .then(({ data }) => {
        form.resetFields();
        dispatch(setAccessToken(data.login.token));
        onClose();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // const test = () => {
  //   apolloClient
  //     .query({
  //       query: gql`
  //         query Walks {
  //           walks {
  //             name
  //           }
  //         }
  //       `,
  //     })
  //     .then(({ data }) => {
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      style={{
        maxWidth: '100%',
      }}
    >
      <Form className={className} form={form} onFinish={onFinish} size="large" layout="vertical">
        <h1>Connexion à votre compte</h1>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item name="password" label="Mot de passe" rules={[{ required: true }]}>
          <Input type="password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Connexion
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalLogin)`
  padding: 25px 20px 15px;
  form {
    margin: 0 auto;
    max-width: 400px;
  }
`;
