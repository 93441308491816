import { IWalk } from '@/types/walk.model';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Dropdown, Menu } from 'antd';

interface Props {
  walk: Partial<IWalk>;
  onWantDelete?: (id: string) => void;
  onWantUpdate?: (walk: Partial<IWalk>) => void;
}

const ContextMenu: React.FC<{
  walk: Partial<IWalk>;
  onWantDelete?: (id: string) => void;
  onWantUpdate?: (walk: Partial<IWalk>) => void;
  close: () => void;
}> = ({ walk, onWantDelete, onWantUpdate, close }) => {
  return (
    <Menu onClick={close}>
      {!!onWantUpdate && (
        <Menu.Item key="update" onClick={() => onWantUpdate(walk)}>
          Modifier
        </Menu.Item>
      )}
      {!!onWantDelete && (
        <Menu.Item danger key="delete" onClick={() => onWantDelete(walk._id!)}>
          Supprimer
        </Menu.Item>
      )}
    </Menu>
  );
};

const WalkCard: React.FC<Props> = ({ walk, onWantDelete, onWantUpdate }) => {
  const history = useHistory();
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  if (!!onWantUpdate || !!onWantDelete) {
    return (
      <Dropdown
        overlay={() => (
          <ContextMenu
            walk={walk}
            onWantDelete={onWantDelete}
            onWantUpdate={onWantUpdate}
            close={() => {
              setShowContextMenu(false);
            }}
          />
        )}
        trigger={['contextMenu']}
        visible={showContextMenu}
        onVisibleChange={(e) => setShowContextMenu(e)}
      >
        <div
          key={walk._id}
          className="card"
          onClick={() => {
            history.push(`/walks/${walk._id}`);
          }}
        >
          <h3>{walk.name}</h3>
          <span>{moment(walk.createdAt).format('L')}</span>
        </div>
      </Dropdown>
    );
  } else {
    return (
      <div
        key={walk._id}
        className="card"
        onClick={() => {
          history.push(`/walks/${walk._id}`);
        }}
      >
        <h3>{walk.name}</h3>
        <span>{moment(walk.createdAt).format('L')}</span>
      </div>
    );
  }
};

export default WalkCard;
