import { Form, Input, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { IWalk } from '@/types/walk.model';
import { apolloClient } from '@/apollo';

interface Props {
  walk: Partial<IWalk> | null;
  onClose: () => void;
  onAdd: (walk: Partial<IWalk>) => void;
  onUpdate: (walk: Partial<IWalk>) => void;
}

const ModalWalk: React.FC<Props> = ({ walk, onClose, onAdd, onUpdate }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (walk) {
      form.resetFields();
      form.setFieldsValue({ ...walk });
    }
  }, [walk]);

  const onFinish = (values: any) => {
    if (!walk?._id) {
      apolloClient
        .mutate({
          mutation: ADD_WALK,
          variables: {
            input: {
              ...values,
            },
          },
        })
        .then((response) => {
          setLoading(false);
          form.resetFields();
          onAdd?.(response.data.addWalk);
          onClose();
        })
        .catch((error) => {
          message.error(error.message);
          setLoading(false);
        });
    } else {
      apolloClient
        .mutate({
          mutation: UPDATE_WALK,
          variables: {
            input: {
              id: walk._id,
              ...values,
            },
          },
        })
        .then((response) => {
          setLoading(false);
          form.resetFields();
          onUpdate?.(response.data.updateWalk);
          onClose();
        })
        .catch((error) => {
          message.error(error.message);
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      title={walk?._id ? 'Modifier la ballade' : 'Créer une nouvelle ballade'}
      visible={!!walk}
      onCancel={onClose}
      onOk={() => form.submit()}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalWalk;

const ADD_WALK = gql`
  mutation AddWalk($input: AddWalkInput!) {
    addWalk(input: $input) {
      _id
      name
    }
  }
`;

const UPDATE_WALK = gql`
  mutation UpdateWalk($input: UpdateWalkInput!) {
    updateWalk(input: $input) {
      _id
      name
    }
  }
`;
