import { Button, Form, Input, message, Modal, Select, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { apolloClient } from '@/apollo';
import { IAttraction } from '@/types/attraction.model';
import { FileImageOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';

interface Props {
  className?: string;
  attractionId: string;
  visible: boolean;
  onClose: () => void;
  onAdd: (attraction: Partial<IAttraction>) => void;
}

const ModalCreateComment: React.FC<Props> = ({ className, attractionId, visible, onClose, onAdd }) => {
  const [form] = useForm();
  const fileRef = useRef<any>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      fileRef.current = null;
      setImageUrl(null);
    }
  }, [visible]);

  const pictureCustomRequest = async (options: any) => {
    const { onSuccess, file, onError } = options;
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });

      fileRef.current = compressedFile;
      setImageUrl(URL.createObjectURL(file));
      onSuccess('done', file);
    } catch (error) {
      console.log(error);
      message.error('File is too big.');
      return;
    }
  };

  const onPictureRemove = async (file: any) => {
    return new Promise<boolean>((resolve) => {
      setImageUrl(null);
      resolve(true);
    });
  };

  const onFinish = (values: any) => {
    setIsSaving(true);
    apolloClient
      .mutate({
        mutation: ADD_COMMENT,
        variables: {
          ...values,
          attractionId,
          upload: fileRef.current,
        },
      })
      .then((response) => {
        setIsSaving(false);
        onAdd(response?.data?.addComment);
        onClose();
      })
      .catch((error) => {
        message.error(error.message);
        setIsSaving(false);
      });
  };

  return (
    <Modal
      title="Nouvelle contribution"
      visible={visible}
      onCancel={onClose}
      onOk={() => form.submit()}
      okText="Valider"
      confirmLoading={isSaving}
      className={className}
      width={800}
      style={{
        maxWidth: '100%',
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="text" label="Commentaire" rules={[{ required: true }]}>
          <Input.TextArea rows={8} />
        </Form.Item>
        <Form.Item name="nickname" label="Pseudo" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Image">
          <Upload
            name="attraction-upload"
            listType="picture-card"
            className={imageUrl ? 'image-uploader' : ''}
            multiple={false}
            showUploadList={false}
            accept="image/*"
            customRequest={pictureCustomRequest}
            onRemove={onPictureRemove}
            style={{
              height: imageUrl ? undefined : '100px',
            }}
          >
            {imageUrl ? (
              <div
                style={{
                  backgroundImage: `url("${imageUrl}")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '100%',
                  width: '100%',
                }}
              />
            ) : (
              <div>
                <FileImageOutlined style={{ fontSize: 36, marginBottom: 10 }} />
                <br />
                Ajouter une image
              </div>
            )}
          </Upload>
        </Form.Item>
        {imageUrl && (
          <Button
            type="primary"
            danger
            style={{ marginBottom: 20, marginTop: -20, width: '100%' }}
            onClick={onPictureRemove}
          >
            {`Supprimer l'image`}
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default styled(ModalCreateComment)`
  width: 100%;
  margin: 0 auto;
  max-width: 590px;
  padding: 55px 20px;

  .image-uploader {
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      height: 345px;
    }
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100%;
  }
`;

const ADD_COMMENT = gql`
  mutation AddComment($nickname: String!, $text: String!, $attractionId: ID!, $upload: Upload) {
    addComment(input: { nickname: $nickname, text: $text, upload: $upload, attractionId: $attractionId }) {
      _id
      nickname
      image
      text
      hidden
    }
  }
`;
