import { Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const MenuLang: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const { className, ...otherProps } = props;

  return (
    <Menu className={className} {...otherProps}>
      <Menu.Item onClick={() => i18n.changeLanguage('fr')}>FR</Menu.Item>
      <Menu.Item onClick={() => i18n.changeLanguage('en')}>EN</Menu.Item>
    </Menu>
  );
};

export default styled(MenuLang)`
  li a {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
`;
