import React, { useState } from 'react';
import styled from 'styled-components';
import { CaretDown16, EarthFilled32, Map32, Menu32, UserAvatarFilledAlt24 } from '@carbon/icons-react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, Dropdown } from 'antd';
import MenuMobile from './MenuMobile';
import MenuUser from './MenuUser';
import { resetState } from '@/redux/authSlice';
import ModalLogin from './ModalLogin';
import ModalSignUp from './ModalSignup';
import { useTranslation } from 'react-i18next';
import MenuLang from './MenuLang';
import { CaretDownOutlined } from '@ant-design/icons';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = ({ className }) => {
  const { accessToken, email } = useAppSelector((state) => state.auth);
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);
  const [showModalSignUp, setShowModalSignUp] = useState<boolean>(false);
  const isAuthentificated = !!accessToken;
  const dispatch = useAppDispatch();
  const {
    i18n: { language },
  } = useTranslation();

  const logout = () => {
    dispatch(resetState());
  };

  return (
    <header className={className}>
      <div className="left">
        <img id="logo" src={`${process.env.PUBLIC_URL}/logo.svg`} />
      </div>
      <div className="center">
        <ul>
          <li>
            <NavLink activeClassName="is-active" to="/explore">
              <EarthFilled32 /> Explorer
            </NavLink>
          </li>
          {isAuthentificated && (
            <li>
              <NavLink activeClassName="is-active" to="/app/walks">
                <Map32 />
                Mes ballades
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div className="right">
        {!isAuthentificated && (
          <React.Fragment>
            <button className="login" onClick={() => setShowModalLogin(true)}>
              Se connecter
            </button>
            <button className="signup" onClick={() => setShowModalSignUp(true)}>{`S'inscrire`}</button>
          </React.Fragment>
        )}
        {isAuthentificated && (
          <Dropdown
            getPopupContainer={(trigger) => trigger}
            className="menu-user"
            overlay={<MenuUser logout={logout} email={email} />}
          >
            <div>
              <UserAvatarFilledAlt24 /> <CaretDown16 />
            </div>
          </Dropdown>
        )}
        <Dropdown overlay={<MenuLang />} getPopupContainer={(trigger) => trigger}>
          <div>
            <Button className="langs">
              {language.toLocaleUpperCase()} <CaretDownOutlined />
            </Button>
          </div>
        </Dropdown>
      </div>
      <Dropdown
        className="menu-mobile"
        overlay={
          <MenuMobile
            isAuthentificated={isAuthentificated}
            logout={logout}
            email={email}
            setShowModalLogin={setShowModalLogin}
            setShowModalSignUp={setShowModalSignUp}
          />
        }
      >
        <Menu32 />
      </Dropdown>
      <ModalLogin visible={showModalLogin} onClose={() => setShowModalLogin(false)} />
      <ModalSignUp visible={showModalSignUp} onClose={() => setShowModalSignUp(false)} />
    </header>
  );
};

export default styled(Header)`
  position: fixed;
  z-index: 2;
  width: 100vw;
  background-color: #343a4f;
  display: flex;
  padding: 0 20px;
  height: 60px;
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

  .left,
  .right {
    position: absolute;
  }

  .left {
    left: 20px;
  }

  .right {
    right: 20px;
    display: flex;
    button {
      cursor: pointer;
      border: none;
      margin-left: 10px;
      width: auto;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.28);
      border-radius: 4px;
      padding: 8px 20px;

      &.login {
        color: #343a4f;
        background: white;
      }
      &.signup {
        color: white;
        background: #8352ca;
      }
      &.langs {
        width: 100%;
        height: 100%;
        border: 1px solid white;
        color: white;
        background: none;
        padding: 8px 5px;
      }
    }

    .menu-user {
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 8px;
      color: #838383;
      cursor: pointer;
    }
  }

  .center {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      width: 150px;
      color: black;
      svg {
        margin-right: 15px;
      }

      &.is-active {
        color: white;
      }
    }
  }

  .menu-mobile {
    display: none;
    position: absolute;
    right: 20px;
  }

  @media (max-width: 648px) {
    .center,
    .right {
      display: none;
    }
    .menu-mobile {
      display: block;
    }
  }
`;
