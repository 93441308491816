import React from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const WalkCardLoading: React.FC<Props> = ({ className }) => {
  return (
    <div className={`${className} card`}>
      <Skeleton.Input style={{ width: 250 }} active={true} />
      <Skeleton.Input style={{ width: 100 }} active={true} size={'small'} />
    </div>
  );
};

export default styled(WalkCardLoading)`
  cursor: default;
`;
