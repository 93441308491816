import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useAppSelector } from '@/redux/hooks';
import { Route } from 'react-router-dom';

interface Props {
  path: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const isAuthentificated = !!useAppSelector((state) => state.auth.accessToken);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthentificated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
