import React from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import WalksRoute from './walks';

const AppPages: React.FC = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${url}/walks`} />} />
      <Route path={`${path}/walks`}>
        <WalksRoute />
      </Route>
    </Switch>
  );
};

export default AppPages;
