import produce, { enableMapSet } from 'immer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

enableMapSet();

export type StateType = {
  accessToken: string | null;
  userId: string | null;
  email: string | null;
};

export const initialStateFunction: () => StateType = () => {
  let accessToken = localStorage.getItem('accessToken');
  let userId = null;
  let email = null;

  if (accessToken) {
    const { userId: _userId, email: _email, exp } = (jwt_decode(accessToken) || {}) as any;
    userId = _userId;
    email = _email;
    if (exp) {
      if (Date.now() >= exp * 1000) {
        accessToken = null;
        userId = null;
        email = null;
      }
    }
  }
  return {
    accessToken,
    userId,
    email,
  };
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateFunction(),
  reducers: {
    resetState: () => {
      localStorage.removeItem('accessToken');
      return initialStateFunction();
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      return produce(state, (draftState) => {
        const accessToken = action.payload;
        draftState.accessToken = accessToken;
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          const decodedJwt = jwt_decode(accessToken) as any;
          draftState.userId = decodedJwt.userId;
          draftState.email = decodedJwt.email;
        } else {
          localStorage.removeItem('accessToken');
          draftState.userId = null;
          draftState.email = null;
        }
      });
    },
  },
});

export const { setAccessToken, resetState } = authSlice.actions;
//export const selectBookList = (state: RootState) => state.book.bookList;
