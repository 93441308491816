import React from 'react';
import styled from 'styled-components';
import { IComment } from '@/types/comment.model';
import moment from 'moment';
import { Button, Divider, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as PDFDocument from 'pdfkit/js/pdfkit.standalone';
import blobStream from 'blob-stream';

interface Props {
  className?: string;
  comment: Partial<IComment> | null;
  onClose: () => void;
  onSeeComment?: (commentId: string) => void;
}

const ModalComment: React.FC<Props> = ({ className, comment, onClose }) => {
  const downloadPDF = () => {
    const doc = new PDFDocument({ size: 'A4', margins: { top: 55, left: 97, right: 97, bottom: 55 } });
    const stream = doc.pipe(blobStream());

    if (comment?.image) {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        // set the font using the arraybuffer returned from the xhr
        // doc.rect(97, 54, 401, 300).fill('grey');
        // doc.image(xhr.response);

        doc
          .rect(97, 55, 401, 300)
          .fill('#F0F0F0')
          .restore()
          .image(xhr.response, 97, 54, { fit: [401, 300], align: 'center', valign: 'center' });

        pdfFillComment(doc, comment!);
      };

      xhr.responseType = 'arraybuffer';
      xhr.open('GET', comment.image!, true);
      xhr.send();
    } else {
      pdfFillComment(doc, comment!);
    }

    stream.on('finish', function () {
      // or get a blob URL for display in the browser
      const url = stream.toBlobURL('application/pdf');
      window.open(url);
    });
  };

  return (
    <Modal
      visible={!!comment}
      onCancel={onClose}
      className={className}
      width={550}
      footer={null}
      style={{
        maxWidth: '100%',
      }}
    >
      {comment && (
        <React.Fragment>
          {comment.image && (
            <div className="container with-image" id="test">
              <div className="image" style={{ backgroundImage: `url("${comment.image}")` }}>
                <div className="bottom" />
                <div className="date">{moment(comment.createdAt!).format('L')}</div>
              </div>
              <p>{comment.text}</p>
              <span className="author">{comment.nickname}</span>
              {/* <div className="theme">
                <span className="dot" /> {comment.theme}
              </div> */}
            </div>
          )}
          {!comment.image && (
            <div className="container" id="test">
              <div className="top">
                {/* <div className="theme">
                  <span className="dot" /> {comment.theme}
                </div> */}
                <div className="date">{moment(comment.createdAt!).format('L')}</div>
              </div>
              <p>{comment.text}</p>
              <span className="author">{comment.nickname}</span>
            </div>
          )}
          <Divider />
          <Button size="small" icon={<DownloadOutlined />} onClick={downloadPDF}>
            Download
          </Button>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default styled(ModalComment)`
  .container {
    padding-top: 25px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 45px;
      .date {
        color: #9b9b9b;
      }
    }

    &.with-image {
      text-align: left;
      justify-content: flex-start;
      .author {
        text-align: right;
      }
      .theme {
        position: absolute;
        top: 45px;
        left: 40px;
      }

      .image {
        background-position: center;
        background-size: cover;
        border-radius: 4px;
        width: 100%;
        height: 345px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        .bottom {
          background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0.8;
        }

        .date {
          position: absolute;
          bottom: 5px;
          right: 5px;
          color: white;
        }
      }
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      overflow: hidden;
      line-height: 18px;
      padding: 0;
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 5px;
      text-align: left;
    }

    .author {
      width: 100%;
      font-style: italic;
      color: #9b9b9b;
      display: inline-block;
      text-align: right;
    }

    .theme {
      border-radius: 20px;
      background: black;
      color: white;
      padding: 2px 10px 2px 8px;
      font-size: 12px;
      top: 20px;
      left: 20px;
      display: flex;
      align-items: center;

      .dot {
        display: inline-block;
        background-color: green;
        height: 12px;
        width: 12px;
        border-radius: 6px;
        margin-right: 5px;
      }
    }
  }
`;

function pdfFillComment(doc: any, comment: Partial<IComment>): void {
  doc
    .fill('#1E1E1E')
    .font('Times-Roman', 80)
    .text('“', 97, 370)
    .font('Helvetica-Bold', 18)
    .moveDown()
    .text(comment.text, 97, 400, {
      width: 412,
      ellipsis: true,
    })
    .moveDown()
    .font('Times-Italic', 12)
    .fill('#8E8E8E')
    .text(`${comment.nickname}, ${moment(comment.createdAt!).format('L')}`, { align: 'right' });

  doc.end();
}
